<script lang="ts">
	import { page } from '$app/stores';
	import { goto } from '$app/navigation';
	import { PUBLIC_APP_ID } from '$env/static/public';
	import { useTranslation } from '@item-ind/app-text-localization';
	import { getCountries } from '@item-ind/localization';
	import { config } from '$lib/app-config';
	import Dropdown, { type DropdownOption } from '$lib/components/dropdown/Dropdown.svelte';
	import { clickOutside } from '$lib/click_outside';
	import { parsePlainLocale } from '$lib/parse_locale';

	$: localization = parsePlainLocale($page?.params?.locale) || config.fallbackLocalization;
	$: t = useTranslation(PUBLIC_APP_ID, localization?.lang);
	$: currentRoute = $page.url.pathname;

	export let visible = false;
	let countryOptions: DropdownOption[] = [];
	let languageOptions: DropdownOption[] = [];

	function updateOptions() {
		const allCountries = ['DE', 'CH', 'FR', 'IT', 'PL', 'MX', 'CN', 'US'];
		const countries = getCountries();
		countryOptions = countries
			.filter((country) => allCountries.includes(country.id))
			.map((country) => {
				const option: DropdownOption = {
					value: country.id,
					label: country.name,
					selected: localization?.country === country.id
				};
				return option;
			});

		const languageLabelById: Map<string, string> = new Map<string, string>([
			['de', 'Deutsch'],
			['en', 'English'],
			['es', 'Español'],
			['fr', 'Français'],
			['it', 'Italiano'],
			['pl', 'Polski'],
			['zh', '中文说中文']
		]);
		const newLanguageOptions: DropdownOption[] = [];
		countries
			.filter((country) => allCountries.includes(country.id))
			.forEach((country) => {
				country.languages.forEach((language) => {
					const languageName = languageLabelById.get(language);
					if (languageName && !newLanguageOptions.find((option) => option.value === language)) {
						const option: DropdownOption = {
							value: language,
							label: languageName,
							selected: localization?.lang === language
						};
						newLanguageOptions.push(option);
					}
				});
			});
		newLanguageOptions.sort((a, b) => {
			if (a.label < b.label) return -1;
			if (a.label > b.label) return 1;
			return 0;
		});
		languageOptions = newLanguageOptions;
	}

	$: localization, updateOptions();

	function close() {
		visible = false;
	}

	function onClickedOutside() {
		// handle click outside the normal event flow to allow others to handle the click
		setTimeout(() => close(), 50);
	}

	function onChanged() {
		const selectedCountry = countryOptions.find((option) => option.selected);
		const selectedLanguage = languageOptions.find((option) => option.selected);
		if (selectedCountry && selectedLanguage) {
			switchLocale(selectedLanguage.value as string, selectedCountry.value as string);
			close();
		}
	}

	function switchLocale(language: string, country: string) {
		let routeSegments = currentRoute.split('/');
		const newLocale = `${language}-${country}`;
		if (routeSegments.length > 1) {
			// replace the current locale with the new locale
			routeSegments[1] = newLocale;
		} else {
			// use the new locale
			routeSegments = ['', newLocale];
		}
		goto(routeSegments.join('/'));
	}
</script>

{#if visible}
	<div
		id="language-and-country-popup"
		class="arrow-top fixed border border-item-grey-20 rounded-4"
		use:clickOutside
		on:click_outside={onClickedOutside}
	>
		<div class="popup-wrapper flex flex-col px-[25px] py-[44px] gap-1 bg-white">
			<h1 class="text-headline-5 font-bold text-item-grey-90">
				{t('locale-selector.countryOfProduction')}
			</h1>
			<Dropdown options={countryOptions} on:dropdownChanged={onChanged}>
				<svelte:fragment slot="placeholder">Select a country...</svelte:fragment>
			</Dropdown>
			<h1 class="text-headline-5 mt-[29px] font-bold text-item-grey-90">
				{t('locale-selector.languagePreference')}
			</h1>
			<Dropdown options={languageOptions} on:dropdownChanged={onChanged}>
				<svelte:fragment slot="placeholder">Select a language...</svelte:fragment>
			</Dropdown>
		</div>
	</div>
{/if}

<style>
	/* popup/arrow adapted from https://codepen.io/imacrab/pen/PXGqGK */
	#language-and-country-popup.arrow-top:before {
		right: 15px;
		top: -4px;
	}
	#language-and-country-popup:before {
		/* tweaking width/rotate/skew to get a flatter tip of the arrow ( i.e. more than 45° ) */
		content: '';
		width: 26px;
		height: 15px;
		transform: rotate(-17deg) skewX(55deg);
		background: white;
		position: absolute;
		z-index: 998;
		border: 1px theme('colors.item-grey-20') solid;
	}
	#language-and-country-popup {
		top: 100px;
		right: 20px;
		width: 370px;
		z-index: 999;
	}
	.popup-wrapper {
		width: 100%;
		background: white;
		position: relative;
		z-index: 998;
	}
</style>
