<script lang="ts">
	import { clickOutside } from '$lib/click_outside';

	export let visible = false;
	let positionStyle = '';

	export function show(element: HTMLElement) {
		const clientRect = element.getBoundingClientRect();
		positionStyle = `top: ${clientRect.bottom}px; left: ${clientRect.left}px; width: ${clientRect.width}px;`;
		visible = true;
	}

	export function close() {
		visible = false;
	}

	function onClickedOutside() {
		// handle click outside the normal event flow to allow dropdown to handle its click
		setTimeout(() => close(), 50);
	}
</script>

{#if visible}
	<div
		style={positionStyle}
		class="fixed z-10 flex flex-col px-[14px] pt-[7px] pb-[7px] gap-2 items-stretch bg-white border-l border-b border-r rounded-b-4 border-item-grey-60"
		use:clickOutside
		on:click_outside={onClickedOutside}
	>
		<slot />
	</div>
{/if}
