<script lang="ts">
	import type { Page } from '@sveltejs/kit';
	import { PUBLIC_CDN_URL } from '$env/static/public';
	import { PUBLIC_APP_ID } from '$env/static/public';
	import { useTranslation } from '@item-ind/app-text-localization';
	import LanguageAndCountrySelector from '$lib/components/localization/LanguageAndCountrySelector.svelte';
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import { config } from '$lib/app-config';
	import { parsePlainLocale } from '$lib/parse_locale';

	let iconVisible: boolean = true;
	let spacerRect: DOMRect;

	function handleSpacerChanged() {
		if (spacerRect) {
			if (iconVisible) {
				iconVisible = spacerRect.width > 0;
			} else {
				iconVisible = spacerRect.width > 137;
			}
		}
	}

	$: spacerRect, handleSpacerChanged();

	$: localization = parsePlainLocale($page.params?.locale) || config.fallbackLocalization;
	$: t = useTranslation(PUBLIC_APP_ID, localization?.lang);

	export let title = '';

	function navigateToPage(route: string) {
		goto(route);
	}

	function buildBreadcrumbPages(page: Page) {
		const routeSegments = page.url.pathname.split('/').filter((segment: string) => segment);

		const pages: BreadcrumbPage[] = [
			{
				title: t('breadcrumb.home').toUpperCase(),
				route: '/' + page?.params?.locale,
				enabled: routeSegments.length > 1
			}
		];

		for (let i = 1; i < routeSegments.length; i++) {
			const route = `/${routeSegments.slice(0, i + 1).join('/')}`;
			const routeSegment = routeSegments[i];
			const keyPrefix = routeSegment;
			const needsLocalization =
				Object.getOwnPropertyNames(page.params).findIndex((n) => page.params[n] === keyPrefix) ===
				-1;

			pages.push({
				enabled: i !== routeSegments.length - 1,
				title: needsLocalization ? t(keyPrefix + '.title').toUpperCase() : routeSegment,
				route: route
			});
		}
		return pages;
	}

	interface BreadcrumbPage {
		title: string;
		route: string;
		enabled: boolean;
	}

	$: breadcrumbPages = buildBreadcrumbPages($page);
</script>

<svelte:head>
	<title>{title}</title>
</svelte:head>
<header class="flex h-24 border-b border-item-grey-20">
	<div class="flex w-full items-center">
		{#if iconVisible}
			<a href={breadcrumbPages[0].route} class="relative flex flex-col">
				<img
					src={`${PUBLIC_CDN_URL}/shared/item_logo.svg`}
					alt="item"
					class="h-[30px] px-[10px]"
					data-testid="logo"
				/>
			</a>
		{/if}
		<div class="flex items-center gap-0.5 ml-when-first">
			{#each breadcrumbPages as page, index (page.title)}
				<button
					type="button"
					class="border-2 rounded"
					disabled={!page.enabled}
					class:border-item-grey-30={!page.enabled}
					class:text-item-grey-30={!page.enabled}
					class:border-item-petrol-dark={page.enabled}
					class:text-item-petrol-dark={page.enabled}
					on:click={() => navigateToPage(page.route)}
				>
					<span class="text-headline-5 font-bold">{page.title}</span>
				</button>
				{#if index !== breadcrumbPages.length - 1}
					<span class="text-headline-5 font-bold p-1" style="color: black;">/</span>
				{/if}
			{/each}
		</div>
		<div bind:contentRect={spacerRect} class="flex-1">
			<!-- This is just a spacer, we use it to hide the icon when spacer is shrunken to zero -->
		</div>
		<div class="flex ml-auto mr-5">
			<LanguageAndCountrySelector />
		</div>
	</div>
</header>

<style>
	.ml-when-first:first-child {
		margin-left: 5px;
	}
</style>
