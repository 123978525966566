<script lang="ts" context="module">
	export interface DropdownOption {
		value: number | string;
		label: string;
		selected?: boolean;
	}

	export const DROPDOWN_CHANGED = 'dropdownChanged';
	export type ChangedDetail = {
		selectedIdx: number;
		selectedValue: number | string;
		selectedLabel: string;
	};
	declare global {
		interface DocumentEventMap {
			[DROPDOWN_CHANGED]: CustomEvent<ChangedDetail>;
		}
	}
</script>

<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import DropdownPopup from '$lib/components/dropdown/DropdownPopup.svelte';

	let popup: DropdownPopup;
	let isOpen = false;
	let selectedIdx = -1;
	let self: HTMLButtonElement;

	export let options: DropdownOption[] = [];

	const dispatch = createEventDispatcher();

	function onClicked() {
		if (isOpen) {
			popup.close();
		} else {
			popup.show(self);
		}
	}

	function onOptionClicked(idx: number) {
		selectedIdx = idx;
		isOpen = false;
		options = options.map((v, i) => {
			v.selected = i === idx;
			return v;
		});
		popup.close();
		const detail: ChangedDetail = {
			selectedIdx: selectedIdx,
			selectedValue: options[selectedIdx].value,
			selectedLabel: options[selectedIdx].label
		};

		dispatch(DROPDOWN_CHANGED, detail);
	}

	function onOptionsChanged(options_: DropdownOption[]) {
		selectedIdx = options_.findIndex((option) => option.selected);
	}

	$: onOptionsChanged(options);
</script>

<button
	type="button"
	class="flex-row justify-between w-full h-[40px] px-[16px] py-[12px] border-l border-t border-r border-item-grey-60 rounded-t-4 text-dropdown-1 text-item-grey-90"
	class:rounded-b-4={!isOpen}
	class:border-b={!isOpen}
	class:z-[11]={isOpen}
	on:click={onClicked}
	bind:this={self}
>
	{#if selectedIdx !== -1}
		{options[selectedIdx].label}
	{:else}
		<slot name="placeholder">Select an option...</slot>
	{/if}
	{#if !isOpen}
		<i class="right-2 icon-chevron-down" />
	{/if}
</button>

<DropdownPopup bind:this={popup} bind:visible={isOpen}>
	{#each options as item, idx}
		<button
			type="button"
			class="flex flex-row h-[32px] px-[4px] py-[12px] border-item-petrol rounded-4 hover:bg-item-grey-10"
			class:border-2={selectedIdx === idx}
			on:click={() => onOptionClicked(idx)}
		>
			<p class="w-full text-left text-dropdown-1 text-item-grey-60">{item.label}</p>
		</button>
	{/each}
</DropdownPopup>
