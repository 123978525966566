<script lang="ts">
	import LanguageAndCountryPopup from '$lib/components/localization/LanguageAndCountryPopup.svelte';

	let popupVisible = false;

	function click() {
		popupVisible = !popupVisible;
	}
</script>

<button
	type="button"
	class="flex-row content-start px-[10px] border-2 border-item-petrol-dark rounded-4 text-left text-item-petrol-dark text-button-2"
	on:click={click}
>
	<i class="inline-flex text-button-icon-2 icon-globe icon" />
</button>

<LanguageAndCountryPopup bind:visible={popupVisible} />
